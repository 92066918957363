@import '~@which/seatbelt/src/styles/styles';

.categoryTypesContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: row;
  gap: $sb-spacing-m;
  margin-top: $sb-spacing-m;

  @include sb-respond-to(medium-up) {
    grid-template-columns: repeat(4, 1fr);
    gap: $sb-spacing-m;
  }
}

.categoryTypesContainerTwo {
  grid-template-columns: repeat(2, 1fr);

  @include sb-respond-to(large) {
    grid-template-columns: repeat(2, 1fr) minmax(auto, 21.4%);
  }

  @include sb-respond-to(xlarge) {
    grid-template-columns: repeat(3, 1fr) minmax(auto, 16.2%);
  }
}

.categoryTypesContainerThree {
  grid-template-columns: repeat(2, 2fr);

  @include sb-respond-to(medium-up) {
    grid-template-columns: repeat(3, 1fr);
  }
  @include sb-respond-to(xlarge) {
    grid-template-columns: repeat(3, 1fr) minmax(auto, 21.4%);
  }
}
