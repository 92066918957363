@import '~@which/seatbelt/src/styles/styles';
@import '../../../../styles/mixins/wrapper';

.maxWidth {
  @include w-page-wrapper(false);

  @include sb-respond-to(xlarge) {
    max-width: 1440px;
  }

  .pageSection {
    padding-top: $sb-spacing-xl;

    &.seoDescriptionSection {
      padding-top: 0;
    }
  }

  .sectionWithDivider {
    padding-bottom: $sb-spacing-2xl;
    border-bottom: 1px solid $sb-colour-border-emphasised;
  }
}
