@import '~@which/seatbelt/src/styles/styles';

.scamEmotionalSupport {
  margin: $sb-spacing-l 0;

  @include sb-respond-to(medium-up) {
    margin: $sb-spacing-3xl 0;
  }

}

.scamImage img {
  margin-bottom: $sb-spacing-l;
}

.supportCards {
  display: flex;
  flex-flow: column;

  @include sb-respond-to(medium-up) {
    flex-flow: row;
    margin: $sb-spacing-2xl 0 0;
  }

  @include sb-respond-to(large) {
    margin: $sb-spacing-3xl 0 0;
  }
}

.supportCard {
  margin: $sb-spacing-m 0 0;

  @include sb-respond-to(medium-up) {
    flex: 1;
    margin: 0;

    &:first-child {
      margin: 0 $sb-spacing-m 0;
    }
  }

  @include sb-respond-to(large) {
    &:first-child {
      margin: 0 $sb-spacing-l 0;
    }
  }

  .supportCardImage {
    vertical-align: bottom;
    width: 100%;
    object-fit: cover;
  }
}

.telephoneLink svg {
  display: none;
}
