@import '~@which/seatbelt/src/styles/styles';

.topRatedVariantTwo2366 {
  display: flex;
  justify-content: space-between;
}

.wrapper {
  display: none;
  background: sb-color('sb-color-black-100');
  color: sb-color('sb-color-white');
  padding: 8px 21px;
}

.topRatedVariantOne2366 {
  text-align: center;
}

.link {
  color: sb-color('sb-color-white');

  &:hover, &:visited {
    color: sb-color('sb-color-white');
  }
}
