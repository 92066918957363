@import '~@which/seatbelt/src/styles/styles';

.currentStory {
  &Header {
    margin-bottom: $sb-spacing-l;
  }

  &Picture {
    background-color: revert;

    &FlexWrapper {
      height: 100%;
      margin-top: $sb-spacing-m;

      @include sb-respond-to(large) {
        display: flex;
        align-items: center;
      }
    }

    &Wrapper {
      margin: $sb-spacing-m 0
        $sb-spacing-3xl * -1 0;

      @include sb-respond-to(medium-up) {
        margin: $sb-spacing-xl
          $sb-spacing-xl
          $sb-spacing-3xl * -1
          $sb-spacing-xl;
      }

      @include sb-respond-to(large) {
        margin: 0 $sb-spacing-m;
      }
    }
  }
}
