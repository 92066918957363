@import '~@which/seatbelt/src/styles/styles';
@import '../../../../styles/mixins/wrapper';


.additionalContentWrapper {
  padding: $sb-spacing-xl 0;

  &Background {
    background-color: sb-color("sb-color-black-05");
  }

  .hr {
    display: block;
    max-width: 70%;
    margin-top: $sb-spacing-l;
    margin-bottom: $sb-spacing-xl;
  }

  .additionalContentLinksSection {
    display: inline-block;
    width: 100%;
    margin: 0 0 $sb-spacing-m 0;

    .links {
      display: block;

      .link {
        padding: 0 0 $sb-spacing-l 0;
      }

      @include sb-respond-to(medium-up) {
        display: grid;

        .link {
          display: inline-block;
          padding: 0 $sb-spacing-l 0 0;
          box-sizing: border-box;
        }
      }
    }

    &:global(.width-1) {
      @include sb-respond-to(medium-up) {
        width: 50%;
      }

      @include sb-respond-to(large) {
        width: 33%;
      }

      .links {
        grid-template-columns: 1fr;
      }
    }

    &:global(.width-2) {
      @include sb-respond-to(large) {
        width: 66%;
      }

      .links {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &:global(.width-3) {
      .links {
        @include sb-respond-to(medium-up) {
          grid-template-rows: 2fr;
          grid-template-columns: 1fr 1fr;

          .link {
            padding-bottom: $sb-spacing-m;
          }
        }

        @include sb-respond-to(large) {
          grid-template-columns: 1fr 1fr 1fr;
        }
      }
    }
  }
}

.grid {
  @include w-page-wrapper(false);

  @include sb-respond-to(xlarge) {
    max-width: 1440px;
  }
}

.content {
  @include sb-text-small-print;
  margin: $sb-spacing-m 0 $sb-spacing-xs;

  p:not(:last-child) {
    margin-bottom: $sb-spacing-l;
  }
}
