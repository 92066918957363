@import '~@which/seatbelt/src/styles/styles';

$table-column-width: 230px;
$toggle-button-bottom: 20vh;
$cell-padding-horizontal: 16;
$page-padding: 30;
$mobile-page-margin: 6.7%;
$tablet-page-margin: 3.5%;
$desktop-page-margin: 3%;
$toggle-transition: transform 0.2s ease;
$margin-transition: 0.2s margin ease;

.tableWrapper {
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;

  @include sb-respond-to(large) {
    overflow: unset;
  }
}

.comparisonTable {
  width: max-content;
  border-collapse: separate;
  border-spacing: 0;
  height: 100%;
  transition: $margin-transition;

  @include sb-respond-to(large) {
    width: -webkit-fill-available;

    @-moz-document url-prefix() {
      width: -moz-available;
    }
  }

  .comparisonTableHead {
    z-index: 4;
  }

  th,
  td {
    width: $table-column-width;
    position: relative;
    border-left: none;
    border-bottom: none;
    vertical-align: top;
    background-color: sb-color('sb-color-white');

    @include sb-respond-to(large) {
      width: 20%;
    }

    &::before,
    &::after {
      content: '';
      height: 13px;
      width: 1px;
      position: absolute;
      right: -2px;
      background-color: sb-color('sb-color-white');
      transform: translate(-1px, 0);
    }

    &::after {
      top: 0;
    }

    &::before {
      bottom: 0;
    }

    &:last-of-type {
      &::after,
      &::before {
        display: none;
      }
    }
  }

  tbody td {
    padding: 13px;
    line-height: 25px;
  }
}

.compareWithAccordion {
  margin-top: $sb-spacing-2xl;
	margin-bottom: $sb-spacing-2xl;

  .comparisonTable .sectionHeader {
    padding-left: 0;
  }
}

.comparisonTableHead {
  z-index: 1;
}

.compareTrayHeadRow {
  height: 100%;
  position: relative;
  transition: $toggle-transition;
}

.star {
  padding: 0 6px 0 0;

  &:last-child {
    padding: 0;
  }
}

.refineCell {
  background: white;
  padding: 25px 13px;
  text-align: left;
}

.refineCellText {
  margin: 0 0 15px;
}

.compareCard {
  display: inline-table;
  width: 100%;
}
.tableHeadCell {
  background: white;
  padding: 25px 13px;
  border-bottom: 1px solid sb-color('sb-color-black-41');
  position: relative;
  text-align: left;
}

.stickyFirstColumn {
  text-align: left;
  position: sticky;
  left: 0;
}

//When table is scrollable certain elements need to change to show they are collapsible
.tableIsScrollable {
  .stickyFirstColumn {
    position: sticky;
    left: 0;
    z-index: 3;
    border-right: none;
    border-bottom: none;
    transition: left 0.2s ease;

    &:not(.refineCell) {
      box-shadow: 2px 6px 12px -2px rgba(0, 0, 0, 0.6);
    }
  }

  .refineCell {
    transition: none;
  }
}

td.sectionHeader,
tbody td.sectionHeader {
  padding-top: $sb-spacing-xl;
  padding-bottom: 15px;
  background-color: $sb-colour-background-muted;
  border: none;

  .sectionHeaderText {
    margin-right: $sb-spacing-s;
    text-transform: capitalize;
    text-align: left;
  }
}

.productFeatureRow {
  z-index: 1;

  @media (hover: none) and (pointer: coarse) {
    &:nth-child(odd) td {
      background-color: sb-color('sb-color-black-05');

      &::before,
      &::after {
        background-color: sb-color('sb-color-black-05');
      }
    }

    &:nth-child(even) td {
      background-color: white;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      &:nth-child(odd),
      &:nth-child(even) {
        td {
          background-color: sb-color(sb-color-yellow-20);

          &::before,
          &::after {
            background-color: sb-color(sb-color-yellow-20);
          }

          &:hover {
            background-color: sb-color(sb-color-yellow-50);

            &::before,
            &::after {
              background-color: sb-color(sb-color-yellow-50);
            }
          }
        }
      }
    }
  }

  .tableDataRowValue:last-child {
    border-right: none;
  }
}

.tableDataRowHead,
.tableDataRowValue {
  border: 1px solid sb-color('sb-color-black-41');
  border-left: none;
  padding: 13px;
}

.tableDataRowHead,
.stickyFirstColumn {
  &::before,
  &::after {
    display: none;
  }

  @include sb-respond-to(large) {
    &::before,
    &::after {
      display: block;
    }
  }
}

.tableDataRowValue {
  text-align: center;

  .tableValue {
    word-break: break-all;
  }
}

.tableDataRowLabel {
  margin-right: $sb-spacing-s;
}

.prosAndCons {
  .tableDataRowValue {
    text-align: left;
  }
}

.expertReview {
  .tableDataRowValue {
    text-align: left;
  }

  .editorialCta {
    display: flex;
    align-items: baseline;

    p {
      width: 100%;
    }
  }

  .padlockIcon {
    margin-right: $sb-spacing-s;
  }

  .verdict {
    margin-bottom: $sb-spacing-m;
  }
}

.prosConsList img {
  mix-blend-mode: multiply;
}

.hidden {
  display: none;
}

// When table header is stuck to top of page
.isSticky {
  .comparisonTable {
    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }

    &::before,
    &::after {
      content: '';
      display: block;
      top: 0;
      height: 300px;
      background-color: white;
      position: fixed;
      z-index: 2;
    }
  }

  .comparisonTableHead {
    width: max-content;
    max-width: 1440px;
    overflow: auto;
    position: fixed;
    top: 0;
    left: 0;
  }

  .compareTrayHeadRow {
    display: flex;
  }

  @include sb-respond-to(large) {
    .comparisonTableHead {
      margin: 0 $grid-gutter-desktop;
      display: table;
      width: -webkit-fill-available;
      border-bottom: 1px solid sb-color('sb-color-black-41');

      @-moz-document url-prefix() {
        display: block;
        width: -moz-available;
      }

      tr {
        @-moz-document url-prefix() {
          display: table;
          width: -moz-available;
        }
      }
    }
  }

  @media only screen and (min-width: 1460px) {
    .comparisonTableHead {
      margin-left: auto;
      margin-right: auto;
      left: unset !important; // this is needed to override an inline style
    }
  }
}

.isAtBottom {
  .comparisonTableHead {
    position: relative;
  }
}

.iconContainer {
  position: absolute;
  top: 20px;
  left: 20px;
  bottom: 0;
  right: 0;
}

.visuallyHidden {
  @include sb-visually-hidden;
}

.tickIcon {
  width: 20px;
  height: 20px;

  path {
    fill: $sb-colour-text-positive;
  }
}

.crossIcon {
  width: 20px;
  height: 20px;

  path {
    fill: $sb-colour-text-negative;
  }
}
