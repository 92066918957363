@import '~@which/seatbelt/src/styles/styles';

$sb-button-height: 44px;

/* Global used to provide styles to override the styles injected by OneTrust
 * Specific styles pulled from packages/seatbelt/src/components/Button/Button.module.scss
 */
:global(button#ot-sdk-btn.ot-sdk-show-settings) {
  @include sb-text-body;
  @include sb-font-medium;

  color: sb-color('sb-color-white');
  background-color: sb-color('sb-color-interactive-blue');
  border: 1px solid sb-color('sb-color-interactive-blue');
  height: $sb-button-height;
  padding: 8px 20px;
  border-radius: calc(#{$sb-button-height} * #{$sb-border-radius-point-5});
  transition: unset;

  &:hover {
    background-color: darken(sb-color('sb-color-interactive-blue'), 20%);
    border: 1px solid darken(sb-color('sb-color-interactive-blue'), 20%);
  }
}
