@import '~@which/seatbelt/src/styles/styles';

.list {
  @include sb-reset-list;

  &Closed li:nth-child(n+7){
    display: none;
  }

  &Expanded li {
    display: block;
  }

  &Item {
    margin-bottom: $sb-spacing-s;
  }
}

.showMore {
  position: absolute;
  width: 100%;
  bottom: -$sb-spacing-2xl;
  left: 0;

  &Wrapper {
    position: relative;
    margin-bottom: $sb-spacing-xl;
  }
}
