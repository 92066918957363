@import '~@which/seatbelt/src/styles/styles';

.csvList {
  &Controls {
    display: flex;
    flex-direction: column;
    padding: $sb-spacing-s;
    background-color: $sb-colour-background-muted;
  }

  &Label {
    margin-bottom: $sb-spacing-xs;
  }

  &DropDown {
    margin-bottom: $sb-spacing-m;
    max-width: fit-content;
  }

  .tableNotes {
    @include sb-font-regular;
    @include sb-text-very-small-print;
  }

  &SelectedTable {
    div[class*='Heading_headingBlock'] {
      margin-bottom: 0;
    }

    h2[class*='sb-text-heading-large'] {
      padding-left: $sb-spacing-s;
      background-color: $sb-colour-background-muted;
      
    }
  }
}
