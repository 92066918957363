@import '~@which/seatbelt/src/styles/styles';

.rightsContentBlock {
  margin: 0 0 $sb-spacing-m 0;

  .headingWithBorder {
    margin-bottom: $sb-spacing-m;
    border-bottom: 1px solid $sb-colour-background-disabled;
  }

  .linkList {
    list-style: none;
    padding: 0;
    margin-bottom: $sb-spacing-xl;

    li {
      margin-bottom: $sb-spacing-m;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      display: inline-block;
    }
  }
}
