@import '~@which/seatbelt/src/styles/styles';

.uploadDropzone {
  border: 1px solid #ddd;
  font-size: $sb-font-size-12;
  overflow: hidden;
  position: relative;
  text-align: center;

  .drag-active {
    background-color: sb-color('sb-color-white');
  }
}

input[type='file'] {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 100%;
}

.uploadPoolArticle {
  margin-bottom: 35px;

  span {
    font-size: $sb-font-size-12;
  }
  &firstSection {
    display: flex;
    flex-wrap: wrap;
    margin-top: $sb-spacing-s;

    &anotherSection {
      padding: 0.25rem;
      width: 50%;
      @include sb-respond-to(medium-up) {
        width: 25%;
      }
      height: 150px;
      border-radius: 6px;
      box-sizing: border-box;

      section {
        display: flex;
      }
      &:hover {
        opacity: 0.55;
      }

      & > section:first-of-type {
        height: 100%;
        position: relative;
      }

      &PreviewDiv {
        display: 'none';
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: $sb-spacing-s;
        border-radius: 6px;
        color: white;
        font-weight: bold;
        background-color: rgba(5, 5, 5, 0.55);

        aside {
          margin-top: auto;
          display: flex;
          justify-content: space-between;
          cursor: pointer;
        }
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: 6px;
      }
    }
  }
}

.uploadButton {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
  color: sb-color('sb-color-interactive-blue');
  text-decoration-line: underline;

  &:hover {
    background-color: transparent;
    border: none;
  }
}

.fileNameText {
  overflow: hidden;
}
