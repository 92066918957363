@import '~@which/seatbelt/src/styles/styles';

$loader-svg-size: 100px;
$loader-svg-position: calc(50% - ($loader-svg-size / 2));

.loader {
  &Wrapper {
    background: sb-color('sb-color-text-white');
    height: 100%;
    left: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 1200;

    @include sb-respond-to('large') {
      z-index: 3;
    }
  }

  &Svg {
    height: $loader-svg-size;
    left: $loader-svg-position;
    position: fixed;
    top: $loader-svg-position;
    width: $loader-svg-size;
  }
}
