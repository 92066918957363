@import '~@which/seatbelt/src/styles/styles';

.container {
  margin-top: $sb-spacing-m;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: $sb-spacing-s;

  @include sb-respond-to(medium-up) {
    margin-top: $sb-spacing-l;
  }

  @include sb-respond-to(medium) {
    grid-template-columns: repeat(4, 1fr);
  }

  @include sb-respond-to(large) {
    grid-template-columns: repeat(4, 1fr);
  }

  @include sb-respond-to(xlarge) {
    grid-template-columns: repeat(5, 1fr);
  }

  .productCard {
    h2 {
      font-weight: 500;
    }

    img {
      border-radius: 3px 3px 0 0;
    }

    a {
      height: 100%;
      display: block;
    }
  }
}
