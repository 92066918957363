@import '~@which/seatbelt/src/styles/styles';

.Subscribe {
  align-items: center;
  border-top: 1px solid $sb-colour-background-disabled;
  display: flex;
  flex-direction: column;
  margin-top: $sb-spacing-xl;
  padding: $sb-spacing-2xl 0;

  @include sb-respond-to(small) {
    margin-left: 6.6%;
    margin-right: 6.6%;
    padding-right: 0;
  }

  @include sb-respond-to(medium) {
    flex-wrap: wrap;
  }

  @include sb-respond-to(medium-up) {
    flex-direction: row;
  }

  &Image {
    background-color: transparent;
    display: flex;
    margin-bottom: $sb-spacing-l;
    width: 115px;

    @include sb-respond-to(medium-up) {
      padding-right: $sb-spacing-l;
    }

    &Picture {
      background-color: transparent;
    }
  }

  &Copy {
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;

    @include sb-respond-to(medium) {
      padding-right: 0;
      width: calc(100% - 115px);
    }

    @include sb-respond-to(medium-up) {
      text-align: left;
    }

    @include sb-respond-to(large) {
      padding-right: $sb-spacing-xl;

      &:after {
        background-color: $sb-colour-background-disabled;
        content: '';
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
      }
    }

    &Title {
      padding-bottom: $sb-spacing-m;
    }
  }

  &CTA {
    display: flex;
    flex-direction: column;

    @include sb-respond-to(medium) {
      padding-left: 0;
      align-self: stretch;
      width: 100%;
    }

    @include sb-respond-to(large) {
      margin-top: $sb-spacing-l;
      padding-left: $sb-spacing-xl;
    }
  }
}
