@import '~@which/seatbelt/src/styles/styles';

.wrapper {
  display: flex;
}

.apply {
  margin-right: $sb-spacing-m;
}

.filterButtonIcon {
  position: relative;
  top: -1px;
  g {
    fill: sb-color('sb-color-white');
  }
}
