@import '~@which/seatbelt/src/styles/styles';

.filter {
  grid-area: filter;
}

.foot {
  grid-area: foot;
}

.product {
  grid-area: product;
}

.sortby {
  grid-area: sortby;

  @include sb-respond-to(small) {
    @include getFullBleedMargins("small");
  }
}
