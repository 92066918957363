@import '~@which/seatbelt/src/styles/styles';

.bundleDetails {
  &SelectedSubscription {
    margin-top: $sb-spacing-m;

    & h2 {
      margin-top: $sb-spacing-m;
      margin-bottom: $sb-spacing-m;
    }

    & h3 {
      margin-bottom: $sb-spacing-s;
    }

    & hr {
      margin-top: $sb-spacing-l;
      background-color: #ccc;
    }
  }
}