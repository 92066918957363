@import '~@which/seatbelt/src/styles/styles';

.sectionWithBorder {
  padding-bottom: $sb-spacing-2xl;
  border-bottom: 1px solid $sb-colour-border-emphasised;
}

.description {
  margin-top: $sb-spacing-m;

  @include sb-respond-to(medium-up) {
    width: 70%;
  }
}

.authorDescription {
  @include sb-respond-to(medium-up) {
    width: 70%;
  }
}

.cards {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $sb-spacing-m;
}

.cardWrapper {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: $sb-spacing-l 0 0 0;

  &:nth-child(even) {
    padding-right: 0;
  }

  @include sb-respond-to(medium-up) {
    flex: 50%;
    width: 50%;
    max-width: 50%;
    padding: $sb-spacing-l $sb-spacing-l 0 0;
  }
}

.expertWrapper {
  margin: $sb-spacing-m 0;
}
