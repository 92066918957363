@import '~@which/seatbelt/src/styles/styles';

.grid {
  display: grid;
  margin: 0 $grid-gutter-mobile;
  grid-column-gap: $grid-column-gap-mobile;

  grid-template-areas:
    "header header"
    "sortby sortby"
    "product product"
    "foot foot"
    "filter filter";
  grid-template-columns: repeat(2, minmax(0, 1fr));

  @include sb-respond-to(medium-up) {
    grid-column-gap: $grid-column-gap-tablet;
    margin: 0 $grid-gutter-tablet;
    grid-template-columns: repeat(12, minmax(0, 1fr));

    grid-template-areas:
      "header header header header header header header header header header header header"
      "sortby sortby sortby sortby sortby sortby sortby sortby sortby sortby sortby sortby"
      "product product product product product product product product product product product product"
      "foot foot foot foot foot foot foot foot foot foot foot foot"
      "filter filter filter filter filter filter filter filter filter filter filter filter";
  }

  @include sb-respond-to(large) {
    grid-column-gap: $grid-column-gap-desktop;
    margin: 0 $grid-gutter-desktop;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-template-rows: auto auto auto 1fr;

    grid-template-areas:
      "header header header header header header header header header header header header"
      "filter filter filter filter sortby sortby sortby sortby sortby sortby sortby sortby"
      "filter filter filter filter product product product product product product product product"
      "filter filter filter filter foot foot foot foot foot foot foot foot";
  }

  @include sb-respond-to(xlarge) {
    grid-column-gap: $grid-column-gap-desktop;
    margin: 0 $grid-gutter-desktop;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-template-rows: auto auto auto 1fr;

    grid-template-areas:
      "header header header header header header header header header header header header"
      "filter filter filter sortby sortby sortby sortby sortby sortby sortby sortby sortby"
      "filter filter filter product product product product product product product product product"
      "filter filter filter foot foot foot foot foot foot foot foot foot";
  }
}
