@import '~@which/seatbelt/src/styles/styles';

.badge {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    margin: 0 $sb-spacing-s 0 0;

    &.blue {
        color: sb-color('sb-color-interactive-blue');
    }

    &.white {
        color: sb-color("sb-color-white");
    }

    &.dark {
        background-color: sb-color("sb-color-black-100");
    }

    &.light {
        background-color: sb-color("sb-color-white");
    }
}

.centre {
    display: flex;
    align-items: center;
}

.padlock {
    min-width: 13px;
    align-self: flex-start;
    margin: 3px  $sb-spacing-s 0 0;

    @include sb-respond-to(medium-up) {
        align-self: center;
        margin: 0 $sb-spacing-s 0 0;
    }
}

.signup {
    margin: 0 4px 0 0;
}
