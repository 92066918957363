@import '~@which/seatbelt/src/styles/styles';

.newsletterSignUp {
  padding: $sb-spacing-l 0;
  position: relative;

  &Wrapper-money {
    border-top: 1px solid $sb-colour-background-inverse;
    border-bottom: 1px solid $sb-colour-background-inverse;
  }
}

.processing {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: $sb-spacing-3xl 0;
}

.processingLoader {
  height: 60px;
  text-align: center;

  svg {
    margin-top: $sb-spacing-s;
  }
}

.successContainer {
  display: flex;
  justify-content: center;

  @include sb-respond-to(large) {
    height: auto;
  }
}

.successHeaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    transform: scale(2);
    margin: 20px;
  }
}

.successHeaderIcon {
  border: 2px solid $sb-colour-text-positive;
  border-radius: 30px;
  margin: $sb-spacing-s;

  svg path {
    fill: $sb-colour-text-positive;
  }
}

.successHeader {
  color: $sb-colour-text-positive;
  padding-bottom: $sb-spacing-2xl;
}

.successSubHeader {
  padding-bottom: $sb-spacing-s;
}
