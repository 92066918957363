@import '~@which/seatbelt/src/styles/styles';

.cardTitle  {
  margin-bottom: $sb-spacing-s;

  a {
    color: $sb-colour-text-link-default;
  }
}

.cardImg {
  margin-bottom: $sb-spacing-m;
}

.cardDesc {
  margin-bottom: $sb-spacing-s;
}
