@import '~@which/seatbelt/src/styles/styles';

.filterButtonInSortby {
  margin: 0 0 0 13px;
  flex-basis: 109px;
  flex-grow: 0;

  &:hover {
    cursor: pointer;
  }

  @include sb-respond-to(medium) {
    margin: 0 13px 0 0;
    order: 1;
  }
  @include sb-respond-to(large) {
    display: none;
  }
}

.filterFunnel {
  position: relative;
  top: -1px;
  g {
    fill: sb-color('sb-color-white');
  }
}

.badge {
  margin: 0 $sb-spacing-s 0 -7px;
  width: 26px;
  height: 26px;

  @include sb-respond-to(medium-up) {
    display: none;
  }

  &.filterBadge {
    display: flex;
  }
}
