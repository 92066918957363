@import '~@which/seatbelt/src/styles/styles';

.productHubLinkSection {
  margin-bottom: $sb-spacing-2xl;
}

.heading {
  border-bottom: 1px solid $sb-colour-background-disabled;
  margin-bottom: $sb-spacing-m;
}

.description {
  margin-bottom: $sb-spacing-m;
}

.linkSectionList {
  padding: 0;
}

.linkSectionListItem {
  list-style: none;
  margin-bottom: $sb-spacing-m;

  &:last-child {
    margin-bottom: 0;
  }
}
