@import '~@which/seatbelt/src/styles/styles';

.productTypeContainer {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: $sb-spacing-l $sb-spacing-xl;
  width: 100%;
  padding-top: $sb-spacing-s;

  @include sb-respond-to(medium-up) {
    flex-direction: row;
  }
}

.productTypeCard {
  flex-shrink: 0;
  width: 100%;

  & article {
    height: 100%;
  }

  @include sb-respond-to(medium) {
    width: calc(50% - 24px);
  }

  @include sb-respond-to(large) {
    width: calc(33% - 29px);

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}
