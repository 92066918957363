@import '~@which/seatbelt/src/styles/styles';

.articleListingHeader {
  color: sb-color('sb-color-black-100');
  padding-top: $sb-spacing-xl;
  max-width: 1440px;
  text-align: center;

  .articleListingSubHeading {
    margin-bottom: $sb-spacing-m;

    @include sb-respond-to(medium-up) {
      margin-bottom: $sb-spacing-l;
    }
  }

  .articleListingCount {
    margin-bottom: $sb-spacing-l;

    @include sb-respond-to(medium-up) {
      margin: 0;
    }
  }
}
