@import '~@which/seatbelt/src/styles/styles';

.brandScoresWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @include sb-respond-to(medium-up) {
    grid-template-columns: repeat(12, 1fr);
  }
}

.recommenderProvider {
  width: 100%;
}

.recommenderProviderTitle {
  background-color: #e30613;
  color: white;
  text-transform: uppercase;
  max-width: max-content;
  padding: 3px 6px;
}

.brandScoresTableRow {
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  &:not(:first-child) {
    border-top: 1px solid $sb-colour-text-default;
    margin-top: 10px;
    padding-top: 10px;
  }
}

.brandScoresTableRowDescription {
  padding-left: 10px;
  display: flex;
  flex-direction: row;

  .tooltip {
    margin-left: 10px;
  }
}

.brandScoresTableRowScore,
.brandScoresTableRowOverall {
  border-left: 1px solid $sb-colour-text-default;
  padding-left: 10px;
}

.brandScoreSignUpRestricted {
  grid-column-start: 1;
  grid-column-end: 3;
  text-align: center;
  margin-top: $sb-spacing-2xl;
  margin-bottom: $sb-spacing-m;

  @include sb-respond-to(medium-up) {
    grid-column-start: 2;
    grid-column-end: 12;
  }
}

.brandScoreSignUpLink {
  background-color: #e30613;
  border-radius: 20px;
  padding: 8px 20px;
  color: $sb-colour-text-inverse;
  margin: 25px 0;
  text-decoration: none;
}

.brandScoreSignUpRestrictedText {
  margin-bottom: 15px;
}

.brandScoreSignUpRestrictedCTAText {
  margin-top: 15px;
}

.brandScoresInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px 0 10px 0;
  align-items: center;
}

.brandScoresInfoImage {
  max-width: 30%;
  width: auto;

  img {
    width: 100%;
    max-height: 80px;
  }
}

.descriptionCell {
  display: flex;
  justify-content: space-between;
}

.brandScores {
  max-width: 100%;
}

.scoreRow {
  margin-bottom: $sb-spacing-2xl;
}

.scoreRowName {
  margin-bottom: $sb-spacing-m;
}

.brandScoresTable {
  justify-items: center;
  align-items: center;

  table {
    margin: auto;
  }
}

.brandScoresTitleColumn {
  display: flex;
  flex-direction: column;
}
