@import '~@which/seatbelt/src/styles/styles';

.visualDataCarousel {
  [class*='cardRowScrollWrapper'] {
    padding-left: 0;
    padding-right: 0;
  }

  [data-testid='button-traverse'] {
    top: calc(50% - 52px);

    svg {
      pointer-events: none;
    }
  }

  h2 {
    margin-left: 0;
    padding-bottom: $sb-spacing-l;
  }

  .imageWrapper {
    position: relative;
    border: 1px solid sb-color('sb-color-black-20');
    width: 205px;

    &:not(:last-of-type) {
      margin-right: 27px;
    }

    .iconWrapper {
      position: absolute;
      bottom: $sb-spacing-s;
      right: $sb-spacing-s;
      border: 1px solid sb-color('sb-color-black-41');
      border-radius: 50%;
      width: 32px;
      height: 32px;
      justify-content: center;
      padding: 0;

      svg {
        margin: 0;
        pointer-events: none;
      }
    }
  }

  [data-testid='modalContainer'] {
    z-index: 1003;

    [data-testid='modalContent'] {
      width: 1123px;
      max-height: none;

      [data-testid='closeButton'] {
        svg {
          position: relative;
          right: -1px;
        }
      }
    }
  }

  .pictureContainer {

    img {
      object-fit: contain;
    }
  }
}
