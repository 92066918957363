@import '~@which/seatbelt/src/styles/styles';

.sectionWithDivider {
  padding-bottom: $sb-spacing-m;
  border-bottom: 1px solid $sb-colour-border-emphasised;

  &.relatedProductsSection {
    padding-bottom: 60px;
  }
}

.sectionFeaturedImage {
  padding-bottom: $sb-spacing-l;

  @include sb-respond-to(medium-up) {
    padding-top: 50px;
  }
}

.textWrapper {
  order: 2;

  @include sb-respond-to(medium-up) {
    order: unset;
  }
}

.pictureWrapper {
  order: 1;
  margin-bottom: $sb-spacing-xl;

  @include sb-respond-to(medium-up) {
    order: unset;
  }
}
