@import '~@which/seatbelt/src/styles/styles';

.heading {
  margin-bottom: $sb-spacing-xs;
}

.description {
  margin: $sb-spacing-s 0;
}

.legalCopy {
  border-top: 1px solid $sb-colour-background-disabled;
  padding: $sb-spacing-l 0;
  margin-top: $sb-spacing-l;

  p:not(:last-child) {
    margin-bottom: $sb-spacing-m;
  }
}

.grid {
  margin: $sb-spacing-m 0 $sb-spacing-l;
  gap: $sb-spacing-xl $sb-spacing-l;

  @include sb-respond-to(large) {
    margin: $sb-spacing-l 0 $sb-spacing-xl;
    row-gap: 50px;
  }
}

.gridWithAllAdvice {
  margin-bottom: 0;
}

.list {
  @include sb-reset-list;
  padding: 0;
}

.listItem:not(:last-child), .nestedList:not(:last-child) {
  margin-bottom: $sb-spacing-m;

  @include sb-respond-to(large) {
    margin-bottom: 20px;
  }
}

.condensedList {
  @include sb-text-body;

  &.grid {
    row-gap: $sb-spacing-xl;
  }

  .list {
    @include sb-text-body;
  }

  .listItem:not(:last-child), .nestedList:not(:last-child) {
    margin-bottom: $sb-spacing-m;
  }

  .listHeading {
    margin-bottom: $sb-spacing-m;
    border-bottom: 1px solid $sb-colour-background-disabled;
  }
}

.ctaWrapper {
  text-align: center;
}

.allAdvice {
  margin-top: $sb-spacing-xl;

  &[hidden] {
    display: none;
  }
}

.allAdviceList {
  padding: 0;
  margin: 0;

  @include sb-respond-to(medium-up) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 21px;
  }

  &.condensedList {
    display: block;
    margin-bottom: $sb-spacing-l;
  }

  .nestedList {
    list-style: none;
    padding: 0;
  }
}

.expertViewWrapper {
  margin-top: $sb-spacing-l;
  margin-bottom: $sb-spacing-3xl;
}
