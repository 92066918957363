@import '~@which/seatbelt/src/styles/styles';

.section {
  max-width: 1440px;
  margin: $sb-spacing-l $sb-spacing-l $sb-spacing-2xl;

  @include sb-respond-to(medium-up) {
    margin: 0 auto $sb-spacing-3xl;
    padding: 0 $sb-spacing-3xl;
  }

  &Text {
    @include sb-respond-to(medium-up) {
      padding-bottom: $sb-spacing-l;
    }
    padding-bottom: $sb-spacing-m;
    text-align: center;
  }

  &Header {
    max-width: 1110px;
  }

  &Content {
    @include sb-respond-to(medium-up) {
      display: flex;
      justify-content: space-around;
      padding-top: $sb-spacing-s;
    }
  }

  &-latestReleases,
  &-topics {
    &Content {
      padding-top: $sb-spacing-s;
    }
  }

  &-latestReleases {
    &Content {
      justify-content: space-between;
      margin-bottom: $sb-spacing-l;

      @include sb-respond-to(large) {
        padding: $sb-spacing-s 0 0;
      }
    }

    article:not(:last-child) {
      @include sb-respond-to(large) {
        margin-right: $sb-spacing-l;
      }
    }
  }

  &ViewAll a {
    color: sb-color('sb-color-black-100');
    text-decoration: none;
  }

  [data-testid='base-link'] {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  [data-testid='article-card-text'] {
    height: 100%;
  }
}
