@import '~@which/seatbelt/src/styles/styles';
@import '../../../styles/mixins/wrapper';


.maxWidth {
  @include w-page-wrapper(false);

  @include sb-respond-to(xlarge) {
    max-width: 1440px;
  }
}

.sectionSpacing {
  padding: $sb-spacing-xl 0;
}

.sectionGrey {
  background-color: sb-color('sb-color-black-05');
}

.bottomOnly {
  padding-top: 0;
}

.topOnly {
  padding-bottom: 0;
}

.latestNews {
  margin-bottom: 0;

  div[class^=CardRow_cardRowScrollWrapper] {
    padding-left: 16px;
  }
}
