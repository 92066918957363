@import '~@which/seatbelt/src/styles/styles';

.addProductsContainer {
  box-sizing: border-box;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23929292FF' stroke-width='2' stroke-dasharray='5' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 4px;
  height: 106px;
  width: 244px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $sb-spacing-m;
}

.link {
  font-size: $sb-font-size-16;
}
