@import '~@which/seatbelt/src/styles/styles';

.productHubBrandLinks {
  margin-bottom: $sb-spacing-l;
}

.brandLinksList {
  list-style: none;
  padding: 0;
  margin: 0;

  .brandLink {
    margin-top: $sb-spacing-m;

    &:first-child {
      margin-top: 0;
    }

    @media only screen and (min-width: 376px) {
      display: inline-block;
      margin-top: $sb-spacing-m;
      margin-right: $sb-spacing-m;
    }
  }
}
