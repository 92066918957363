@import '~@which/seatbelt/src/styles/styles';
@import '../../../styles/mixins/wrapper';

.categoryHeroBleed {
  background-color: sb-color('sb-color-black-05');
}

.sectionGreyBleed {
  background-color: sb-color('sb-color-black-01');
}

.productHubGrid {
  @include w-page-wrapper(true);
}

.brandSection,
.categoryTypesSection {
  margin-bottom: $sb-spacing-2xl;

  @include sb-respond-to(large) {
    margin-bottom: $sb-spacing-3xl;
  }
}

.categoryTypesSection {
  margin-top: $sb-spacing-l;
}
