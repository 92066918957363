@import '~@which/seatbelt/src/styles/styles';

.brandsWrapper {
  padding: $sb-spacing-l;
  border-radius: $sb-border-radius-m;
  background: $sb-colour-background-brand-gold;

  .headingWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    margin-bottom: $sb-spacing-m;

    h2 {
      padding-bottom: 0;
      margin-right: $sb-spacing-m;
    }

    .relatedArticleLink {
      margin-bottom: $sb-spacing-s;
    }
  }
    
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: $sb-spacing-m;
  }
}
