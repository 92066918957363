@import '~@which/seatbelt/src/styles/styles';

.skipButton {
  background-color: sb-color('sb-color-text-white');
  border-radius: $sb-border-radius-4;
  border: none;
  color: sb-color('sb-color-interactive-blue');
  height: 0;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  text-align: center;
  text-decoration: underline;
  width: 0;

  &:focus {
    z-index: 10000;
    height: 65px;
    width: fit-content;
    max-width: 172px;
    padding: 18px 15px;
  }
}
