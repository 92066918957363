@import '../../../../../../../node_modules/@which/seatbelt/src/styles/styles';

.howWeTest {
  background-color: $sb-color-brand-blue-20;
  padding: $sb-spacing-l;

  @include sb-respond-to(large) {
    padding: $sb-spacing-xl;
  }

  &Grid {
    display: grid;
    grid-gap: $sb-spacing-m;
    grid-template-columns: 1fr;

    @include sb-respond-to(large) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .title {
    padding-bottom: $sb-spacing-l;
  }

  .heading {
    display: flex;
  }

  .icon {
    margin-right: $sb-spacing-s;

    path {
      fill: $sb-color-black-100;
    }
  }

  .description {
    padding-top: $sb-spacing-xs;
    padding-left: 28px;
  }
}
