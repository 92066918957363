@import '~@which/seatbelt/src/styles/styles';

.article {
  display: grid;
  grid-gap: $sb-spacing-m;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  position: relative;
  margin-bottom: $sb-spacing-l;

  @include sb-respond-to(large) {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .time {
    background: rgba(sb-color('sb-color-white'), 0.8);
    padding: 0 $sb-spacing-xs;
    border-left: sb-border($sb-border-width-1, 'sb-color-black-20');
    border-top: sb-border($sb-border-width-1, 'sb-color-black-20');
    border-top-left-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    @include sb-respond-to(medium-up) {
      color: sb-color('sb-color-black-60');
      grid-column: 1 / 3;
      position: revert;
      order: revert;
      background: revert;
      border-radius: revert;
      padding: 2px 0 0;
      border: 0;
    }

    &Offset {
      @include sb-respond-to(medium-up) {
        position: relative;
        top: $sb-spacing-l;
      }

      @include sb-respond-to(large) {
        position: revert;
        top: 0;
      }
    }
  }

  .card {
    grid-column: 1 / 13;

    @include sb-respond-to(medium-up) {
      grid-column: 3 / 13;
    }

    @include sb-respond-to(large) {
      grid-column: 3 / 12;
    }

    > div:first-child > a {
      margin-top: $sb-spacing-m;

      @include sb-respond-to(medium-up) {
        margin-top: 0;
      }
    }
  }
}
