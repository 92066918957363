@import '~@which/seatbelt/src/styles/styles';

.richTableCell {
  &Wrapper {
    display: flex;
    flex-direction: column;
  }

  &RecommendedBadge {
    margin-right: - $sb-spacing-m;
    margin-left:  - $sb-spacing-m;
    margin-bottom: $sb-spacing-xs;
  }

  &Badge {
    margin-bottom: $sb-spacing-xs;
  }

  &TooltipRow {
    display: flex;
    gap: 10px;
  }
}
