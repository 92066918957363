@import '~@which/seatbelt/src/styles/styles';

.container {
  .title {
    font-weight: 700;
    padding-bottom: $sb-spacing-m;
  }

  .links {
    padding: 0;
    margin: 0 0 $sb-spacing-xs;

    @include sb-respond-to(medium) {
      display: grid;
      grid-template-rows: repeat(3, auto);
      grid-template-columns: repeat(2, 1fr);
      grid-gap: $sb-spacing-m $sb-spacing-xl;
      grid-auto-flow: column;
    }

    @include sb-respond-to(large) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, auto);
      grid-gap: $sb-spacing-m $sb-spacing-xl;
      grid-auto-flow: column;
    }
  }

  .link {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: $sb-spacing-m;
    justify-content: start;
    align-items: baseline;
    cursor: pointer;
    padding: 10px 0;

    @include sb-respond-to(medium) {
      grid-template-columns: repeat(2, auto);
    }

    @include sb-respond-to(medium-up) {
      padding: 0;
    }
  }

  .all {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    grid-gap: $sb-spacing-m;
    justify-content: center;
    align-items: center;
    margin-top: $sb-spacing-s;

    @include sb-respond-to(medium) {
      grid-template-columns: repeat(3, max-content);
    }
  }
}
