@import '~@which/seatbelt/src/styles/styles';

.infoBannerContainer {
  padding: $sb-spacing-l;
  margin: $sb-spacing-m 0;
  background-color: sb-color('sb-color-yellow-20');
  &.error {
    background-color: #{sb-color('sb-color-error-red')}20;
  }
  &.correct {
    background-color: #{sb-color('sb-color-correct-green')}20;
  }
}

.infoBannerTitle {
  margin-bottom: $sb-spacing-s;
}

.infoBannerContent {
  font-size: 16px;
  line-height: 20px;
  a {
    color: sb-color('sb-color-interactive-blue');
  }
}
