@import '~@which/seatbelt/src/styles/styles';
@import '../../../../styles/mixins/wrapper';

.grid {
  @include w-page-wrapper(false);

  @include sb-respond-to(xlarge) {
    max-width: 1440px;
  }
}

.image {
  display: none;

  @include sb-respond-to(medium-up) {
    display: flex;
    min-height: 185px;
    object-fit: cover;
    align-items: center;
  }
}

.fullWidthLayout {
  .gridContentWrapper {
    padding-bottom: $sb-spacing-2xl;
    border-bottom: 1px solid $sb-colour-background-disabled;
  }

  .image {
    @include sb-respond-to(medium-up) {
      align-items: flex-end;
    }
  }
}
