@import '~@which/seatbelt/src/styles/styles';
@import '../../../../styles/mixins/wrapper';
@import '../../../../styles/mixins/link-animation';

.grid {
  @include w-page-wrapper(false);

  @include sb-respond-to(xlarge) {
    max-width: 1440px;
  }
}

.reviews {
  &Wrapper {
    padding: $sb-spacing-xl 0;

    @include sb-respond-to(large) {
      padding: $sb-spacing-3xl 0;
    }
  }

  &Standfirst {
    margin-bottom: $sb-spacing-l;
    p a {
      color: $sb-colour-text-link-default;
      @include link-animation;
    }
  }

  &CardRow {
    margin-left: -13px; // Margin override to ensure the card-row aligns correctly with text
    margin-bottom: $sb-spacing-l;

    div[class^='CardRow__cardRowScrollWrapper'] {
      // Padding overrides to match designs & ensure focus outline is visible
      padding-top: $sb-spacing-xs;
      padding-left: $sb-spacing-xs;
    }

    //SB-TODO - double underscore > single underscore
    div[class^='CardRow_cardRowScrollWrapper'] {
      // Padding overrides to match designs & ensure focus outline is visible
      padding-top: $sb-spacing-xs;
      padding-left: $sb-spacing-xs;
    }
  }

  &CardRowNew {
    margin-left: 0;
  }

  &ButtonWrapper {
    text-align: center;

    &.leftAlignButton {
      text-align: left;
    }
  }

  &MobileMemberCard {
    width: 100%;
    max-width: 375px;
    margin: 0 auto $sb-spacing-xs;
  }

  &DesktopMemberCard {
    width: 320px;
    margin-left: $sb-spacing-m;
  }

  &ProductCard {
    margin-left: $sb-spacing-m;
    width: 320px;
    // Need to make this explicit so it works when used on grey background for whc-reverse-product-hub-layout feature
    background-color: $sb-colour-background-default;

    &:first-child {
      margin-left: 0;
    }
  }
}

.exclusiveCard {
  display: flex;

  [data-testid='card-container'] {
    &::before {
      content: '';
      background-color: sb-color(sb-color-which-red);
      width: 75px;
      height: 8px;
      position: absolute;
      left: 25px;
      top: 0;
    }
  }

  &Text {
    margin-bottom: $sb-spacing-m;
  }

  &LinkWrapper {
    margin-bottom: $sb-spacing-l;
  }

  &JoinLink {
    background-color: sb-color(sb-color-which-red);
    border: none;

    &:hover {
      background-color: #b5040f;
      border: none;
    }
  }

  .loginWrapper {
    white-space: nowrap;
  }

  &LoginLink {
    //TODO CTX-1475 - Use via Seatbelt props when available rather than overriding this
    @include sb-font-medium();
  }
}
