@import '~@which/seatbelt/src/styles/styles';

.scamsNavigation {
  h2 {
    padding-bottom: 0;
    margin-bottom: $sb-spacing-l;
  }

  h2 > span {
    white-space: unset;
  }

  @include sb-respond-to(medium) {
    margin: $sb-spacing-3xl 0 0;

    > h2 {
      margin-bottom: $sb-spacing-xl;
    }
  }

  @include sb-respond-to(large) {
    margin: $sb-spacing-xl 0 0;

    > h2 {
      margin-bottom: $sb-spacing-3xl;
    }
  }

  > div {
    padding-bottom: 0;
  }
}

.subVerticalCalloutWrapper {
  margin: 0;

  @include sb-respond-to(medium) {
    margin: $sb-spacing-l 0;
  }

  @include sb-respond-to(large) {
    margin: $sb-spacing-xl 0
      $sb-spacing-l;
  }
}

.calloutWrapper {
  @include sb-respond-to(medium) {
    margin: 0 -10.3%;
  }
}
