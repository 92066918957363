@import '~@which/seatbelt/src/styles/styles';

.item {
  border: 1px solid sb-color('sb-color-black-20');
  border-radius: $sb-border-radius-4;
  padding: $sb-spacing-m;
  min-height: 100px;
  position: relative;
  height: 100%;
}

.removeButton {
  cursor: pointer;
  padding: 0;
  position: absolute;
  right: $sb-spacing-m;
  top: $sb-spacing-m;
}

.manufacturer {
  margin: 0 $sb-spacing-xl 5px 0;
}

.comparisonTableitem {
  width: 100%;
  margin: 0;
  overflow: hidden;
  word-wrap: break-word;
  height: 100%;
}

.cardText {
  word-break: break-word;
}
