@import '~@which/seatbelt/src/styles/styles';

.Promo {
  &Cta {
    margin-top: $sb-spacing-m;
  }

  &VariationWrapper {
    display: flex; 
    align-items: center;
  }

  &TitleVariation {
    margin-left: $sb-spacing-m;
    width: 80%;
  
  }
    
  &VariationContainer {
    margin: $sb-spacing-m 0;
    width: 100%;
  }

  &ChevronIcon {
    padding-left: $sb-spacing-xs;
    margin-right: $sb-spacing-m;
    align-self: center;

  }

  &ChevronIcon path {
    fill: $sb-colour-background-inverse
  }

  &VariationContainersWrapper1 {
    max-width: 400px;
  }

  &VariationContainersWrapper2 { 
    @media (min-width:620px) {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;
    }
  }

  &VariationContainersWrapper2>* {
    flex: 1 1 45%;
    margin-right: $sb-spacing-m;
    margin-top: $sb-spacing-s;
  }

}
