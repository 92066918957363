@import '~@which/seatbelt/src/styles/styles';

$letter-spacing: $sb-line-height-25;
$letter-spacing-md-up: $sb-line-height-28;

.articleLetter {
  margin: $sb-spacing-xl 0;
  padding-top: $letter-spacing * 3;
  padding-bottom: $letter-spacing * 3;
  background-color: sb-color('sb-color-yellow-20');
  margin-left: -7.6%;
  margin-right: -7.6%;
  @include sb-respond-to(medium-up) {
    margin-left: 0;
    margin-right: 0;
  }

  .letterWrapper {
    padding-left: 6.6%;
    padding-right: 6.6%;
    @include sb-respond-to(medium-up) {
      padding-left: $sb-spacing-l;
      padding-right: $sb-spacing-l;
    }
    padding-bottom: $letter-spacing;

    p {
      margin: 0 0 $letter-spacing !important;
      @include sb-respond-to(medium-up) {
        margin: 0 0 $letter-spacing-md-up !important;
      }
    }
  }
}
