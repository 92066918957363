@import '~@which/seatbelt/src/styles/styles';

.toolFrameworkError {
  &BulletBlack {
    &::before {
      color: sb-color('sb-color-black-100') !important;
    }
  }

  &Text {
    margin-top: $sb-spacing-l;
    margin-bottom: 0;
  }
}
