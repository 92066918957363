@import '~@which/seatbelt/src/styles/styles';

.container {
  display: grid;
  justify-content: start;
  grid-template-areas: "border pre" "border title" "border body";
  grid-gap: $sb-spacing-s
    $sb-spacing-m;

  .pre {
    grid-area: pre;
  }

  .title {
    grid-area: title;
  }

  .body {
    grid-area: body;
    margin-bottom: 0;
  }

  &::before {
    content: "";

    grid-area: border;
    width: 3px;
    background-color: sb-color("sb-color-black-100");
    height: calc(100% - 12px);
    transform: translate(0, 6px);

    @include sb-respond-to(medium-up) {
      height: calc(100% - 16px);
      transform: translate(0, 9px);
    }
  }
}
