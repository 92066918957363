@import '~@which/seatbelt/src/styles/styles';

.TFToolEntryPoint {
  border: 1px solid sb-color('sb-color-black-20');
  border-radius: 4px;
  padding: $sb-spacing-l;
  margin-top: $sb-spacing-m;

  @include sb-respond-to(medium-up) {
    padding-bottom: 28px;
  }

  &QuestionVariant {
    border: 4px solid sb-color('sb-color-blue-100');
    padding: 19px $sb-spacing-m;

    @include sb-respond-to(medium-up) {
      padding-top: $sb-spacing-l;
      padding-bottom: 28px;
    }
  }

  &Badge {
    text-transform: uppercase;
    margin-bottom: $sb-spacing-s;

    @include sb-respond-to(medium-up) {
      margin-bottom: $sb-spacing-m;
    }
  }

  &Title {
    margin-bottom: $sb-spacing-s;

    @include sb-respond-to(medium-up) {
      margin-bottom: 18px;
    }
  }

  &BodyWrapper {
    @include sb-respond-to(medium-up) {
      display: flex;
    }
  }

  &Content {
    @include sb-respond-to(medium-up) {
      flex: 1;
      padding-right: $sb-spacing-s;
    }

    p {
      margin-bottom: $sb-spacing-m;
    }
  }

  &Question {
    margin-bottom: $sb-spacing-s;

    @include sb-respond-to(medium-up) {
      margin-bottom: $sb-spacing-m;
    }
  }

  .image {
    margin: $sb-spacing-l 0 0;
    max-width: 160px;
    display: block;

    @include sb-respond-to(medium-up) {
      flex: 1;
      margin: 0;
      max-width: 292px;
    }
  }
}

.optionList {
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  padding-left: 0;
  margin: 0;

  @include sb-respond-to(medium-up) {
    display: flex;
  }

  &Item {
    flex: 1 1 0%;

    &:not(:last-child) {
      margin-bottom: $sb-spacing-s;

      @include sb-respond-to(medium-up) {
        margin-right: 20px;
        margin-bottom: 0;
      }
    }

    &Box {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 132px;
      max-width: 100%;
      border: 1px solid sb-color('sb-color-black-20');
      border-radius: 4px;
      text-decoration: none;
      color: sb-color('sb-color-black-100');
      background-color: sb-color('sb-color-white');

      &:hover,
      &:focus,
      &:active {
        background-color: sb-color('sb-color-blue-20');
      }

      @include sb-respond-to(medium-up) {
        height: 138px;
      }

      &Icon {
        width: 50px;
        height: 40px;
        margin-bottom: $sb-spacing-s;
      }

      &Label {
        margin-bottom: $sb-spacing-xs;
        font-size: $sb-font-size-16;
        font-family: $sb-font-family-medium;

        @include sb-respond-to(medium-up) {
          font-size: $sb-font-size-18;
        }
      }

      &Example {
        font-size: $sb-font-size-14;
      }
    }
  }
}
