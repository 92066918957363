@import '~@which/seatbelt/src/styles/styles';

.categoryHero {
  position: relative;
  width: 100%;
  background-color: sb-color("sb-color-black-05");

  .heroImage {
    background-color: transparent;
  }

  .content {
    display: flex;
    padding: 30px 0;
    margin: 0 auto;

    @include sb-respond-to(medium) {
      min-height: 250px;
    }

    @include sb-respond-to(medium-up) {
      align-items: center;
    }

    @include sb-respond-to(large) {
      min-height: 350px;
    }
  }

  .usingGridWidth {
    .gridItem {
      position: relative;
    }

    .content {
      width: auto;
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    text-align: left;
    align-items: flex-start;
    width: 100%;

    @include sb-respond-to(medium) {
      width: 82%;
    }

    @include sb-respond-to(medium-up) {
      align-items: center;
      text-align: center;
    }

    @include sb-respond-to(large) {
      width: 66%;
      max-width: 893px;
    }

    @include sb-respond-to(print) {
      text-align: left;
      align-items: flex-start;
    }
  }

  .description {
    display: block;
  }

  .ctaButton {
    margin-top: $sb-spacing-m;
  }

  &.hasImage {
    .text {
      @include sb-respond-to(medium-up) {
        margin: 0;
        flex-basis: 50%;
        width: 50%;
        max-width: 663px;
        text-align: left;
        align-items: flex-start;
      }
    }

    .image {
      display: none;

      @include sb-respond-to(medium-up) {
        display: block;
        width: 100%;
        position: absolute;
        bottom: 0;
        right: 0;
        max-width: 50%;
      }

      @include sb-respond-to(print) {
        display: none;
      }
    }

    @include sb-respond-to(print) {
      background: none;
    }
  }

  @include sb-respond-to(print) {
    background: none;
  }
}
