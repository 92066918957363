@import '~@which/seatbelt/src/styles/styles';
@import '../../../../styles/mixins/wrapper';

.adviceLinks {
  margin-top: $sb-spacing-xl;
  @include sb-respond-to(large) {
    margin-top: $sb-spacing-2xl;
  }

  @include sb-respond-to(small) {
    margin-left: $grid-gutter-mobile;
    margin-right: $grid-gutter-mobile;
  }

  &Grid {
    @include w-page-wrapper(false);

    @include sb-respond-to(xlarge) {
      max-width: 1440px;
    }
    @include sb-respond-to(large) {
      margin-bottom: $sb-spacing-l;
    }
    margin-bottom: $sb-spacing-m;
  }

  &TopList {
    @include sb-reset-list;
    display: flex;
    flex-direction: column;
    gap: $sb-spacing-m;

    @include sb-respond-to(small) {
      margin-top: $sb-spacing-m;
    }
  }

  &List {
    &Visible {
      @include sb-reset-list;
      display: grid;
      gap: $sb-spacing-m;
      margin-bottom: $sb-spacing-l;

      @include sb-respond-to(medium) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      @include sb-respond-to(large) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }

      #productHubShowMoreCard {
        height: 100%;
      }
    }

    &Hidden {
      display: none;
    }
  }
}
