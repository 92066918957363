@import '~@which/seatbelt/src/styles/styles';

.errorModal {
  &Content {
    padding: $sb-spacing-xl;
  }

  h3 {
    @include sb-text-title-400;
    @include sb-font-bold;
  }

  h3,
  p {
    margin-bottom: $sb-spacing-m; 
  }
}
