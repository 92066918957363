@import '~@which/seatbelt/src/styles/styles';

.scamChecklistCallout {
  width: 100%;
  margin: 0 auto;

  &Title {
    margin: 0 0 $sb-spacing-m;
    justify-content: center;
    text-align: center;
  }

  &Body {
    text-align: center;
    margin: $sb-spacing-l 0;
  }
}

.scamChecklist {
  position: relative;

  &Buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @include sb-respond-to(medium-up) {
      flex-direction: row;
    }

    [class*='ButtonLink'] {
      margin-bottom: $sb-spacing-m;
      text-align: center;
      justify-content: center;

      &:hover {
        cursor: pointer;
      }

      @include sb-respond-to(medium-up) {
        margin-bottom: 0;

        &:first-child {
          margin-right: 21px;
        }
      }
    }

    &__printButton {
      display: none;

      @include sb-respond-to(large) {
        display: inherit;
      }
    }
  }

  &ImageContainer {
    margin: $sb-spacing-l 0;
  }

  &__list {
    margin: 0;
    max-height: 390px;
    transition: max-height 0.7s ease-in-out;
    overflow-y: hidden;

    @include sb-respond-to(medium-up) {
      padding: 0 10.4%;
    }

    &Inner {
      display: flex;
      flex-direction: column;
      padding: 0;
      margin: 0;

      &Item {
        margin: $sb-spacing-m 0;

        @include sb-respond-to(medium-up) {
          margin: $sb-spacing-l 0;
        }
      }
    }
  }

  &__expanded {
    transition: max-height 1s ease-in-out;
    max-height: 5000px;
  }

  &__checkbox {
    margin: 0 0 $sb-spacing-l 0;

    label {
      font-family: $sb-font-family-medium;
      font-size: $sb-font-size-19;
      line-height: $sb-line-height-25;

      @include sb-respond-to(medium-up) {
        font-size: $sb-font-size-24;
        line-height: $sb-line-height-30;
      }
    }
  }

  &__text {
    padding-left: 35px;
  }

  &__expandButton {
    cursor: pointer;
    margin-top: $sb-spacing-l;

    @include sb-respond-to(medium-up) {
      margin-top: $sb-spacing-3xl;
    }
  }

  &__gradient {
    background: linear-gradient(0deg, $sb-colour-background-default 0%, $sb-colour-background-inverse 100%);
    bottom: 50px;
    height: 122px;
    position: absolute;
    width: 100%;

    @include sb-respond-to(medium-up) {
      bottom: 86px;
    }
  }

  &__expandingContainer {
    display: flex;
    justify-content: center;
  }
}
